// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as Sentry from "@sentry/react";

import { isProduction } from "@jvs-group/jvs-mairistem-tools";

import pkg from "../../package.json";
import { getEnv } from "../utils/env";

export const initialize = (store, history) => {
	if (isProduction()) {
		Sentry.init({
			dsn: "https://e92831904769194bdd2dc866b4527d87@sentry.jvs-mairistem.dev/2",
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.browserProfilingIntegration(),
				Sentry.replayIntegration({
					maskAllText: true,
					blockAllMedia: true,
				}),
				...(getEnv() === "production"
					? []
					: [
							Sentry.feedbackIntegration({
								colorScheme: "system",
								triggerLabel: "Signaler un problème",
								formTitle: "Signaler un problème",
								submitButtonLabel: "Envoyer",
								cancelButtonLabel: "Annuler",
								confirmButtonLabel: "Confirmer",
								addScreenshotButtonLabel: "Ajouter une capture d'écran",
								removeScreenshotButtonLabel: "Supprimer la capture d'écran",
								nameLabel: "Nom",
								namePlaceholder: "Votre nom",
								emailLabel: "Adresse e-mail",
								emailPlaceholder: "votre.email@jvs.fr",
								isRequiredLabel: "Champ obligatoire",
								messageLabel: "Message",
								messagePlaceholder:
									"Décrivez le problème que vous avez rencontré",
								successMessageText: "Merci pour votre retour !",
							}),
						]),
			],
			tracesSampleRate: 1.0,
			profilesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			release:
				getEnv() === "development"
					? `${pkg.name.replace(/^@[\w-]+(\.)?[\w-]+\//, "")}@dev`
					: `${pkg.name.replace(/^@[\w-]+(\.)?[\w-]+\//, "")}@${pkg.version}`,
			environment: getEnv(),
		});

		store.subscribe(() => {
			const { user, context } = store.getState();

			Sentry.setUser(
				user
					? {
							id: user.utilisateur,
							email: user.email,
							username: `${user.prenom} ${user.nom}`,
						}
					: null,
			);
			Sentry.setContext("tenant", !_.isEmpty(context) ? context.tenant : null);
			Sentry.setContext(
				"database",
				!_.isEmpty(context) ? context.database : null,
			);
		});
	}
};

export default isProduction() ? Sentry : null;
